import React from "react"
import Layout from "../components/layout"
import {graphql} from "gatsby";
import { css} from '@emotion/react'

export default function BlogPost({ data }) {
    const post = data.markdownRemark
    return (
        <Layout>
            <div>
                <img
                    src={post.frontmatter.blogcoverimage}
                    alt=""
                    css={css`
                  width: 100%;
                `}
                />

            </div>
            <div
                css={css`
                  padding: 0 5%;
                `}
            >
                <sub>{post.frontmatter.date}</sub>
                <h1
                    css={css`
  text-transform: capitalize;                
`}
                >{post.frontmatter.title}</h1>

                <p>{post.excerpt}</p>
            </div>
        </Layout>
    )
}

export const query = graphql`
query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
        html
        frontmatter {
            title
            blogcoverimage
            date
        }
        excerpt
    }
}
`